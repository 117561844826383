import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { getHeaders, otpSend, otpValidate, subscribeWifi } from "../../ApiCalls/LoginAuthenticateApi/api_v1_login_validate";
import { getNet, getDeNet, getDecryptMsisdn } from "../../ApiCalls/Auth/api_v1_auth";
import { otpInput, generalErrorMessage } from "./lgoinComponentFunctions";
import styles from '../../styles/login/login.module.css';
import bgImg from '../../images/v1/login/bg.avif'
import logo from '../../images/v1/login/logo.png'
import PrivacyPolicy from './PrivacyPolicy';
const Login = () => {
    const [submitOff, setSubmitOff] = useState(0);
    const cookies = new Cookies();
    // const [AccessToken,setAccessToken] = useState(localStorage.getItem("token"));
    let location = useLocation();
    let navigate = useNavigate();
    const [infoState, setInfoState] = useState({
        msisdn: "",
        AccessToken: localStorage.getItem("token"),
        isLoading: false,
        clickid: "",
        cpid: "",
        dataType: null,
        p: "",
    });
    const [errorInfo, setErrorInfo] = useState({
        errorShowOtp: 0,
        errMessageOtp: "",
        errorGeneral: 0,
        errorMessage: "",
        generalErrorMessage: "We can not process your request at this time, please try again later"

    })
    // const getOTPText = "Get OTP";
    const getOTPText = "SUBSCRIBE";
    const subscribeText = "SUBSCRIBE";
    const verifyText = "VERIFY";
    const [textBtn, setButtonText] = useState(0);
    let btnClass = (textBtn == 1) ? "" : "";
    const clickId = useRef(new URLSearchParams(window.location.search).get("clickid") ?? cookies.get('clickid'));
    const cpId = useRef(new URLSearchParams(window.location.search).get("cpid") ?? cookies.get('cpid'));
    const base = useRef(
        new URLSearchParams(window.location.search).get("base") ?? cookies.get('base')
    );
    const uid = useRef(new URLSearchParams(window.location.search).get("user") ?? cookies.get('uid'));
    const user = useRef(new URLSearchParams(window.location.search).get("u") ?? cookies.get('u'));
    const h = useRef(new URLSearchParams(window.location.search).get("user") ?? cookies.get('user'));
    const source = useRef(new URLSearchParams(window.location.search).get("source") ?? cookies.get('source'));
    const tc = useRef(new URLSearchParams(window.location.search).get("tc") ?? cookies.get('tc'));
    const rmsid = useRef(cookies.get('rmsid'));
    let exp = (new URLSearchParams(window.location.search)).get("exp");
    const msisdnFocus = useRef(null);
    const confirmBtnRef = useRef(false);
    const otpRef1 = useRef(null);
    const otpRef2 = useRef(null);
    const otpRef3 = useRef(null);
    const otpRef4 = useRef(null);
    const oneTimeCall = useRef(true);
    const [showAccessPopup, setShowAccessPopup] = useState(0);
    const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    // let isUuid = cookies.get('uid');
    const utype = useRef(new URLSearchParams(window.location.search).get("uuid`") ?? cookies.get('u'));
    const dataType = useRef("wUser");
    const currentD = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(currentD.getFullYear() + 1);
    useLayoutEffect(() => {
        // console.log("mounted");
        msisdnFocus.current.select();
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Login | Ding";
    }, []);

    useEffect(() => {
        if (oneTimeCall.current) {
            oneTimeCall.current = false;
            if (exp) {
                setShowAccessPopup(5);
            } else {
                const redirect = new URLSearchParams(window.location.search).get("r");
                if (redirect === null) {
                    const queryParameters = window.location.search; // Get all query parameters
                    const redirectURL = process.env.REACT_APP_SESSION_URL + queryParameters + (queryParameters ? "&uuid=-1&redirect=2" : "?uuid=-1&redirect=2");
                    window.location.href = redirectURL;
                    return;
                }
                user.current = new URLSearchParams(window.location.search).get("user");
            }
            if (location.state) {
                const { unsubState } = location.state;
                if (unsubState) {
                    generalErrorMessage(errorInfo, setErrorInfo, "You are successfully unsubscribed");
                    setErrorInfo({ ...errorInfo, errorGeneral: 1, generalErrorMessage: "You are successfully unsubscribed" });
                } else {
                    setErrorInfo({ ...errorInfo, errorGeneral: 1, generalErrorMessage: "We can not process your request at this time, please try again later" });
                }

                setTimeout(() => {
                    setErrorInfo({ ...errorInfo, errorGeneral: 0, generalErrorMessage: "We can not process your request at this time, please try again later" });
                    navigate('/login');
                }, 5000);
            }
            else if (user.current !== undefined && user.current !== '-1') {
                //mobile data

                getDecryptMsisdn({ encMsisdn: user.current }).then(function (res) {

                    const status = res.data.data.suv;
                    cookies.set('u', user.current, { path: '/', expires: nextYear });
                    utype.current = user.current;
                    msisdnFocus.current.readOnly = true;
                    // msisdnFocus.current.value = user.current;
                    msisdnFocus.current.value = '0' + user.current.substring(2, 12);
                    confirmBtnRef.current = true;
                    dataType.current = "mUser";
                    // const logoutPrevent = new URLSearchParams(window.location.search).get("r");
                    // if (logoutPrevent == 2) {
                    //     console.log(logoutPrevent)
                    //     confirmBtnRef.current = false;
                    //     return;
                    // }
                    if (status == 1) {
                        cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                        setTimeout(
                            () => navigate('/home'),
                            1000
                        );
                        return;
                    }
                    getOtp(msisdnFocus.current.value);
                    if (exp) {
                        setShowAccessPopup(4); //mobile popup
                    }
                })

            } else {
                //wifi
                utype.current = -1;
                user.current = -1; //set user to -1 for wifi signal
                if (exp) {
                    setShowAccessPopup(1); //wifi popup
                }
            }

        }

    }, []);
    const handleWifiContinue = (event) => {
        setShowAccessPopup(0);
        msisdnFocus.current.select();
    }
    const handleMobileContinue = (event) => {
        setShowAccessPopup(0);
        msisdnFocus.current.readOnly = true;
    }

    function getOtp(number) {
        let otpPayload = {
            "msisdn": number,
            "h": h.current,
            "u": user.current,
            "tc": tc.current,
            "clickid": clickId.current,
            "cpid": cpId.current,
            "utype": dataType.current,
            "uid": uid.current,
            "rmsid": rmsid.current,
            "source": source.current,
            "b": base.current
        }
        otpSend(otpPayload)
            .then((response) => {

                if (utype.current == -1 && exp) {
                    setButtonText(2);
                }
                else {
                    setButtonText(1);
                }

                const accessToken = response.headers['access_token'];
                localStorage.setItem('token', accessToken);
                const decoded = jwt_decode(accessToken);
                const dataType = decoded.userInfo['dataType'];
                setInfoState({ ...infoState, msisdn: decoded.userInfo['msisdn'], AccessToken: accessToken, clickid: decoded.userInfo['clickid'], cpid: decoded.userInfo['cpid'], dataType: decoded.userInfo['dataType'], p: decoded.userInfo['p'] });
                let token_uid = decoded.userInfo['uid'];
                cookies.set('uid', token_uid, { path: '/', expires: nextYear });
                let auth = decoded.userInfo['auth'];
                let whitelist = decoded.userInfo['whitelist'];
                if (auth == 1 && whitelist == 1) {
                    cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                    navigate('/home');
                    return true;
                }
                let p = decoded.userInfo['p'];
                p = atob(p);
                if (dataType == 1) { //muser
                    msisdnFocus.current.readOnly = true;
                    if (p === "") {
                        otpInput(p, 0);
                    } else {
                        otpInput(p, 1);
                    }
                }
                else { //wuser
                    otpInput(p, 0);
                }
            }, (error) => {
                if (error.response.data.message !== "") {
                    generalErrorMessage(errorInfo, setErrorInfo, error.response.data.message);
                }
                else {
                    generalErrorMessage(errorInfo, setErrorInfo);
                }
            });
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (confirmBtnRef.current === true) {
            return;
        }
        if (msisdnFocus.current.value == "") {
            return;
        }
        const number = msisdnFocus.current.value;
        // console.log(number, 257);
        getOtp(number);
    }
    const handleOtpValidate = (event) => {
        event.preventDefault();
        if (msisdnFocus.current.value == "") {
            return;
        }
        if (textBtn === 0) {
            return;
        }
        setErrorInfo({
            ...errorInfo, errorShowOtp: 0,
            errorGeneral: 0
        });
        const inputs = document.querySelectorAll('#OTPInput > *[id]');
        let compiledOtp = '';
        for (let i = 0; i < inputs.length; i++) {
            compiledOtp += inputs[i].value;
        }
        if (compiledOtp === "") {
            return;
        }
        // var compiledOtp = document.getElementById('otp').value;
        let validatePayload = {
            "msisdn": infoState.msisdn,
            "pin_code": compiledOtp
        }
        otpValidate(validatePayload)
            .then((response) => {
                if (response.data.message === "ok") {
                    const accessToken = response.headers['access_token'];
                    localStorage.setItem('token', accessToken);
                    const suv = response.data.data.suv;
                    if (suv != null && suv == 0 && utype.current == -1 && exp) {
                        setShowAccessPopup(2);
                    }
                    // else if (suv != null && suv == 0 && utype != -1) {
                    //     setShowAccessPopup(2);
                    // }
                    else {
                        cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                        setTimeout(
                            () => navigate('/home'),
                            1000
                        );
                    }
                }

            }, (error) => {
                // console.log(error.response.status);
                otpRef1.current.value = "";
                otpRef2.current.value = "";
                otpRef3.current.value = "";
                otpRef4.current.value = "";
                // otpRef5.current.value = "";
                otpRef1.current.select();
                if (error.response.status === 422) {
                    generalErrorMessage(errorInfo, setErrorInfo);

                } else if (error.response.status === 400 && error.response.data.message === "invalid login credentials") {
                    setErrorInfo({ ...errorInfo, errorGeneral: 1 });
                    setTimeout(
                        () => setErrorInfo({ ...errorInfo, errorGeneral: 0 }),
                        5000
                    );
                    console.log(error.response.data.message);
                }
                else {
                    setErrorInfo({
                        ...errorInfo, errorShowOtp: 1,
                        errMessageOtp: error.response.data.message
                    });
                    // console.log(error.response.data.message);
                }
            });
    }

    const subscribeWifiUser = (event) => {
        subscribeWifi().then((response) => {
            if (response.data.message === "ok") {
                const accessToken = response.headers['access_token'];
                localStorage.setItem('token', accessToken);
                setShowAccessPopup(3);
                cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                setTimeout(
                    () =>
                        navigate('/home'),
                    2000
                );
            }
        }, (error) => {
            console.log(error.response.status);
            if (error.response.status === 422) {
                setShowAccessPopup(0);
                generalErrorMessage(errorInfo, setErrorInfo);

            } else if (error.response.status === 400 && error.response.data.message === "invalid login credentials") {
                setShowAccessPopup(0);
                setErrorInfo({ ...errorInfo, errorGeneral: 1 });
                setTimeout(
                    () => setErrorInfo({ ...errorInfo, errorGeneral: 0 }),
                    5000
                );
                console.log(error.response.data.message);
            } else {
                setShowAccessPopup(0);
                setErrorInfo({
                    ...errorInfo, errorShowOtp: 1,
                    errMessageOtp: error.response.data.message
                });
                console.log(error.response.data.message);
            }
        });
    }

    const handleChange = (event) => {
        var number = event.target.value;
        if (number.length == 11 && submitOff == 0) {
            getOtp(number);
            msisdnFocus.current.readOnly = true;
            otpRef1.current.select();
            setErrorInfo({
                ...errorInfo, errorShowOtp: 0,
                errorGeneral: 0
            });
            // document.getElementById("otp-field0");
            setTimeout(
                () => msisdnFocus.current.readOnly = false,
                2000
            );
        }
        if (submitOff == 1 && number.length < 11) {
            setSubmitOff(0);
        }
    }

    function handlePrivacyPolicy() {
        setShowPrivacyPolicy(!ShowPrivacyPolicy);
    }

    return (
        <>
            {
                showAccessPopup == 5 ?
                    <section className={styles.accessPopup}>
                        <div className={styles.lds_ring}><div></div><div></div><div></div><div></div></div>
                        <h1 className={styles.accessPopupWh1}>
                            Loading...
                        </h1>
                    </section> :
                    showAccessPopup == 1 ?
                        <section className={styles.accessPopup}>
                            <h1 className={styles.accessPopupWh1}>
                                Seems you are <br /> using WiFi <br /> Please enter your <br /> Mobile Number <br /> So we can verify your subscription
                            </h1>
                            <button className={styles.accessPopupWbtn1} onClick={handleWifiContinue}>CONTINUE</button>
                        </section>
                        : showAccessPopup == 2 ?
                            <section className={styles.accessPopup}>
                                <h1 className={styles.accessPopupWh1}>
                                    Oh! You are <br /> not <br />  subscribed
                                </h1>
                                <button className={styles.accessPopupWbtn1} onClick={subscribeWifiUser}>Subscribe Now</button>
                            </section>
                            : showAccessPopup == 3 ?
                                <section className={styles.accessPopup}>
                                    <h1 className={styles.accessPopupWh1}>
                                        You are <br /> subscribed!
                                    </h1>
                                </section>
                                : showAccessPopup == 4 ?
                                    <section className={styles.accessPopup}>
                                        <h1 className={styles.accessPopupWh1}>
                                            "Oh! Seems you <br /> are not <br /> subscribed" <br /> We sent you PIN <br /> over SMS <br /> Press the button <br /> if you received <br /> the SMS
                                        </h1>
                                        <button className={styles.accessPopupWbtn1} onClick={handleMobileContinue}>CONTINUE</button>
                                    </section>
                                    : null
            }
            <img src={bgImg} alt="." style={{ position: "fixed", height: "100%", width: "100%", zIndex: "-3" }} />
            <main className={styles.main + ' d-flex justify-content-center flex-column align-items-center' + (showAccessPopup == 1 || showAccessPopup == 2 || showAccessPopup == 3 || showAccessPopup == 4 || showAccessPopup == 5 ? ' filterBlur' : " n")}>
                {/* <h1 className={styles.logoText}>ding</h1> */}
                <img src={logo} alt="..." style={{ width: "150px", zIndex: "-1" }} />
                <section className={styles.formSection + ` d-flex justify-content-center flex-column align-items-center mt-30`}>
                    <p className={styles.loginFormTitle + ' mb-5i'}>Enter your mobile number</p>
                    <p className={styles.pinMessage}> {(errorInfo.errorGeneral == 1) ? `${errorInfo.generalErrorMessage}` : `${errorInfo.errorMessage}`}</p>
                    {/* <form onSubmit={handleSubmit} className='text-center'> */}
                    <input type="tel" className={styles.form_input} placeholder="03*********" name="phone" required="True" minLength="11" maxLength="11" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} id="field" autoComplete="off" ref={msisdnFocus} onChange={(e) => handleChange(e)}></input>
                    {/* <button className={styles.otpButton + '  btn mt-15 mb-15 ' + `${btnClass}`} type='submit'>
                            CONFIRM
                        </button> */}
                    {/* </form> */}
                    <p className={styles.otpText + ' mt-15 mb-0'}>
                        We have sent you an OTP to the above number.
                        {/* Enter Phone Number */}
                    </p>
                    <p className={styles.pinMessage} id="pinMessage"> {(errorInfo.errorShowOtp == 1) ? `${errorInfo.errMessageOtp}` : null}</p>
                    <form id="subscribe" className="text-center">
                        <div className='d-flex justify-content-center' id='OTPInput'>
                            <input className="w-40px h-50px outfitRegular fs-24 m-2 text-center otpInput" id="otp-field0" maxLength="1" type="tel" pattern="[0-9]" inputMode="numeric" autoComplete="off" ref={otpRef1}></input>

                            <input className="w-40px h-50px outfitRegular fs-24 m-2 text-center otpInput" id="otp-field1" maxLength="1" type="tel" pattern="[0-9]" inputMode="numeric" autoComplete="off" ref={otpRef2}></input>

                            <input className="w-40px h-50px outfitRegular fs-24 m-2 text-center otpInput" id="otp-field2" maxLength="1" type="tel" pattern="[0-9]" inputMode="numeric" autoComplete="off" ref={otpRef3}></input>

                            <input className="w-40px h-50px outfitRegular fs-24 m-2 text-center otpInput" id="otp-field3" maxLength="1" type="tel" pattern="[0-9]" inputMode="numeric" autoComplete="off" ref={otpRef4}></input>

                        </div>
                        <input type="hidden" id="otp" name="verifyphone" value=""></input>
                        <button className={styles.otpButton + '  btn mt-15 mb-15 ' + `${btnClass}`} id="btnsubmit" type='button' onClick={handleOtpValidate}>
                            {(textBtn == 0) ? getOTPText : (textBtn == 1) ? subscribeText : (textBtn == 2) ? subscribeText : getOTPText}
                        </button>

                    </form>

                    <div className='wmx-350'>
                        <p className={`${styles.consent} line-height-15`} style={{ letterSpacing: "0.2px" }}>Prepaid customers will be charged upto PKR10 daily</p>
                        <p className={`${styles.consent} line-height-15 `}>Post-Paid customers will be charged PKR200 monthly</p>
                        <p className={`${styles.consent} line-height-15 `}>To Unsubscribe, send UNSUB-DING to 7025</p>
                        <p className={`${styles.consent} line-height-15 `}>All prices are tax inclusive</p>
                    </div>
                    <div className='wmx-350 mt-4'>
                        {!ShowPrivacyPolicy ? <p className={`${styles.consent} mb-2 cursor-pointer`} onClick={handlePrivacyPolicy}>Privacy Policy</p> :
                            <PrivacyPolicy handlePrivacyPolicy={handlePrivacyPolicy} />}

                    </div>
                </section>

            </main >
        </>
    );
}

export default React.memo(Login);