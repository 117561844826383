import React, { useEffect, useRef, useState } from 'react';
import styles from "../../styles/animations_styles/first_visit.module.css";
import arrowUp from "../../images/v1/welcome/arrow_up.png";
import arrowLeft from "../../images/v1/welcome/arrow_left.png";
import goldCoins from "../../images/v1/welcome/gold_coins.png";
import redeemCoins from "../../images/v1/welcome/redeem_coins.png";
import { useNavigate } from "react-router-dom";
const FirstVisitDemoAnimationV1 = ({ hideLogoAnimation }) => {
    let navigate = useNavigate();
    const mainBox = useRef(null);
    const [showingBox, setShowingBox] = useState(0); //total 5 boxes 
    const [fadeInOut, setFadeInOut] = useState(1);
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment showingBox by 1, but ensure it doesn't exceed 5
            setFadeInOut(1);
            setTimeout(() => {
                setShowingBox(prevBox => (prevBox < 6 ? prevBox + 1 : clearInterval(intervalId)));
            }, 800);
            setTimeout(() => {
                setFadeInOut(0);
            }, 1000);

        }, 7000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        if (showingBox === 6) {
            clearIntervalAndNavigate();
        }
    }, [showingBox]); // Add showingBox as dependency

    function clearIntervalAndNavigate() {
        localStorage.setItem("dv", 3);
        window.location.href = "/home/landing";
    }

    return (
        <>
            <div style={{ position: "fixed", height: "100%", width: "100%", zIndex: "10", background: "#191919", top: "0", bottom: "0", left: "0", right: "0" }}></div>
            <div className={styles.mainDemo} ref={mainBox} onContextMenu={(e) => e.preventDefault()}>
                {showingBox == 1 ?
                    <div className={`${styles.welcomeDingWrapper} ${(fadeInOut == 0) ? ' n ' : `${styles.fadeOut}`} text-white`}>
                        <span style={{ width: "5.96px", height: "5.09px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "24px", right: "56.56px", position: "absolute" }}></span>
                        <span style={{ width: "2px", height: "2.41px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", top: "114.69px", left: "22px", position: "absolute" }}></span>
                        <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "202.48px", left: "52.02px", position: "absolute" }}></span>
                        <span style={{ width: "11.91px", height: "10.19px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "210.51px", right: "35.26px", position: "absolute" }}></span>
                        <h2 style={{ fontSize: "24px", textAlign: "center", color: "#FFCA0A", fontWeight: 700 }}>
                            Welcome to Ding
                        </h2>
                        <h2 className='text-center fs-16'>
                            Your one stop for all your <br />
                            <span>entertainment, infotainment and edutainment</span>
                        </h2>
                        <div className="d-flex justify-content-center align-items-center mt-10">
                            <div className="snippet" data-title="dot-pulse">
                                <div className="stage">
                                    <div className="dot-pulse"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : showingBox == 2 ?

                        <div className={`${styles.welcomeDingWrapper} ${(fadeInOut == 0) ? ' n ' : `${styles.fadeOut}`} text-white`}>
                            <span style={{ width: "5.96px", height: "5.09px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "24px", right: "56.56px", position: "absolute" }}></span>
                            <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "41.8px", left: "45.02px", position: "absolute" }}></span>

                            <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "139.43px", right: "13.78px", position: "absolute" }}></span>

                            <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "202.48px", left: "52.02px", position: "absolute" }}></span>
                            <span style={{ width: "11.91px", height: "10.19px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "210.51px", right: "35.26px", position: "absolute" }}></span>
                            <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                                <img src={arrowUp} alt="..." style={{ width: "30.1px", height: "30.1px" }} />
                            </div>
                            <h2 style={{ fontSize: "24px", textAlign: "center", color: "#FFCA0A", fontWeight: 700 }}>
                                Swipe Up
                            </h2>
                            <h2 className='text-center fs-16'>
                                Swipe up for awesome videos.<br />
                                It's as easy as magic on your <br /> fingertips!
                            </h2>
                            <div className="d-flex justify-content-center align-items-center mt-10">
                                <div className="snippet" data-title="dot-pulse">
                                    <div className="stage">
                                        <div className="dot-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : showingBox == 3 ?

                            <div className={`${styles.welcomeDingWrapper} ${(fadeInOut == 0) ? ' n ' : `${styles.fadeOut}`} text-white`}>
                                <span style={{ width: "5.96px", height: "5.09px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "24px", right: "56.56px", position: "absolute" }}></span>
                                <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "41.8px", left: "45.02px", position: "absolute" }}></span>

                                <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "139.43px", right: "13.78px", position: "absolute" }}></span>

                                <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "202.48px", left: "52.02px", position: "absolute" }}></span>
                                <span style={{ width: "11.91px", height: "10.19px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "210.51px", right: "35.26px", position: "absolute" }}></span>
                                <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                                    <img src={arrowLeft} alt="..." style={{ width: "10.1px", height: "19.1px" }} />
                                    <img src={arrowLeft} alt="..." style={{ width: "10.1px", height: "19.1px" }} />
                                </div>
                                <h2 style={{ fontSize: "24px", textAlign: "center", color: "#FFCA0A", fontWeight: 700 }}>
                                    Swipe Left
                                </h2>
                                <h2 className='text-center fs-16'>
                                    Swipe left for trivia fun where <br /> learning meets playtime magic
                                </h2>
                                <div className="d-flex justify-content-center align-items-center mt-10">
                                    <div className="snippet" data-title="dot-pulse">
                                        <div className="stage">
                                            <div className="dot-pulse"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            : showingBox == 4 ?

                                <div className={`${styles.welcomeDingWrapper} ${(fadeInOut == 0) ? ' n ' : `${styles.fadeOut}`} text-white`}>
                                    <span style={{ width: "5.96px", height: "5.09px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "24px", right: "56.56px", position: "absolute" }}></span>
                                    <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "41.8px", left: "45.02px", position: "absolute" }}></span>

                                    <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "139.43px", right: "13.78px", position: "absolute" }}></span>

                                    <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "202.48px", left: "52.02px", position: "absolute" }}></span>
                                    <span style={{ width: "11.91px", height: "10.19px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "210.51px", right: "35.26px", position: "absolute" }}></span>
                                    <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                                        <img src={goldCoins} alt="..." style={{ width: "50.1px", height: "50.1px" }} />
                                    </div>
                                    <h2 style={{ fontSize: "24px", textAlign: "center", color: "#FFCA0A", fontWeight: 700 }}>
                                        Earn Gold Coins
                                    </h2>
                                    <h2 className='text-center fs-16'>
                                        Every second you spend to watch <br /> video, you earn one gold coin
                                    </h2>
                                    <div className="d-flex justify-content-center align-items-center mt-10">
                                        <div className="snippet" data-title="dot-pulse">
                                            <div className="stage">
                                                <div className="dot-pulse"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : showingBox == 5 ?

                                    <div className={`${styles.welcomeDingWrapper} ${(fadeInOut == 0) ? ' n ' : `${styles.fadeOut}`} text-white`}>
                                        <span style={{ width: "5.96px", height: "5.09px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "24px", right: "56.56px", position: "absolute" }}></span>
                                        <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "41.8px", left: "45.02px", position: "absolute" }}></span>

                                        <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "139.43px", right: "13.78px", position: "absolute" }}></span>

                                        <span style={{ width: "8.34px", height: "7.13px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "202.48px", left: "52.02px", position: "absolute" }}></span>
                                        <span style={{ width: "11.91px", height: "10.19px", background: "linear-gradient(132.48deg, #F2BC1A 13.3%, #AA7B00 91.57%)", borderRadius: "50%", rotate: "104deg", top: "210.51px", right: "35.26px", position: "absolute" }}></span>
                                        <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                                            <img src={redeemCoins} alt="..." style={{ width: "50.1px", height: "50.1px" }} />
                                        </div>
                                        <h2 style={{ fontSize: "24px", textAlign: "center", color: "#FFCA0A", fontWeight: 700 }}>
                                            Redeem Coins
                                        </h2>
                                        <h2 className='text-center fs-16'>
                                            When you have enough coins, you <br /> can redeem to get your favorite <br /> reward
                                        </h2>
                                        <div className="d-flex justify-content-center align-items-center mt-10">
                                            <div className="snippet" data-title="dot-pulse">
                                                <div className="stage">
                                                    <div className="dot-pulse"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
            </div>

        </>
    )
}


export default React.memo(FirstVisitDemoAnimationV1);
